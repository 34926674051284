import { component } from '../../../utilities/component'
import { Text } from '../text/Text'

import {
    InputContainer,
    InputLabel,
    InputField,
    InputTextarea,
    InputErrorHint
} from './Input.styled'

interface InputProps {
    name: string
    label: string
    placeholder?: string
    textarea?: boolean
    required?: boolean
    error?: string
}

export const Input = component<InputProps>((properties) => {
    return (
        <InputContainer>
            <InputLabel htmlFor={properties.name}>
                <Text color={properties.error ? 'primary' : 'foreground'} font='heading' size={16} weight={700} transform='uppercase'>
                    {properties.label}
                    {properties.required && (
                        <strong>&nbsp;*</strong>
                    )}
                </Text>
            </InputLabel>
            {!properties.textarea && <InputField id={properties.name} name={properties.name} placeholder={properties.placeholder} data-error={Boolean(properties.error)}/>}
            {properties.textarea && <InputTextarea id={properties.name} name={properties.name} placeholder={properties.placeholder} data-error={Boolean(properties.error)}/>}
            {properties.error && (
                <InputErrorHint>
                    <Text color='primary' size={12} weight={700}>
                        {properties.error}
                    </Text>
                </InputErrorHint>
            )}
        </InputContainer>
    )
})

import styled, { keyframes } from 'styled-components'
import { colorWithOpacity } from '../../../utilities/color'

const fadeIn = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`

const spin = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`

export const ContactPageContainer = styled.main`
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding-top: 80px;
    padding-bottom: 64px;
    animation: ${fadeIn} 200ms linear;
    
    @media (max-width: ${props => props.theme.breakpoint.laptop}px) {
        padding: 64px 80px 0;
    }
    
    @media (max-width: ${props => props.theme.breakpoint.tablet}px) {
        padding: 40px;
    }
    
    @media (max-width: ${props => props.theme.breakpoint.mobile}px) {
        padding: 40px 24px;
    }
`

export const ContactPageGrid = styled.section`
    display: grid;
    row-gap: 32px;
    column-gap: 32px;
    grid-template-columns: repeat(3, 1fr);
    
    @media (max-width: ${props => props.theme.breakpoint.tablet}px) {
        row-gap: 24px;
        column-gap: 24px;
        grid-template-columns: repeat(2, 1fr);
    }
    
    @media (max-width: ${props => props.theme.breakpoint.mobile}px) {
        grid-template-columns: 1fr;
    }
`

export const ContactDetailsSection = styled.article`
    grid-column-start: 1;
    grid-column-end: span 2;
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding-bottom: 24px;
`

export const ContactDetailsLineItem = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    color: ${props => props.theme.color.foreground};
    
    & span {
        opacity: 0.66;
    }
`

export const ContactPageHeading = styled.header`
    grid-column-start: 1;
    grid-column-end: span 2;
    display: flex;
    flex-direction: column;
    gap: 32px;
    text-align: justify;
`

export const ContactPageForm = styled.form`
    grid-column-start: 1;
    grid-column-end: span 2;
    display: flex;
    flex-direction: column;
    gap: 24px;
    
    &[hidden] {
        display: none;
    }
`

export const ContactPageButtons = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 24px;
    padding-top: 16px;
`

export const ContactPageSpinnerContainer = styled.div`
    grid-column-start: 1;
    grid-column-end: span 2;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 600px;
`

export const ContactPageSubmissionMessage = styled.div`
    grid-column-start: 1;
    grid-column-end: span 2;
`

export const ContactPageSpinner = styled.div`
    font-size: 10px;
    margin: 50px auto;
    text-indent: -9999em;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: ${props => props.theme.color.primary};
    background: linear-gradient(to right, ${props => props.theme.color.primary} 10%, ${props => colorWithOpacity(props.theme.color.primary, 0)} 42%);
    position: relative;
    animation: ${spin} 1s infinite linear;
    transform: translateZ(0);
    
    &::before {
        width: 50%;
        height: 50%;
        background: ${props => props.theme.color.primary};
        border-radius: 100% 0 0 0;
        position: absolute;
        top: 0;
        left: 0;
        content: '';
    }
    
    &::after {
        background: ${props => props.theme.color.background};
        width: 83%;
        height: 83%;
        border-radius: 50%;
        content: '';
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
`

import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'

import { Root } from './components/Root'
import { store } from './store/store'
import { theme } from './utilities/theme'
import { BrowserRouter } from 'react-router-dom'

function main(): void {
    const root = document.getElementById('root') as HTMLElement

    createRoot(root).render(
        <StrictMode>
            <Provider store={store}>
                <ThemeProvider theme={theme}>
                    <BrowserRouter>
                        <Root />
                    </BrowserRouter>
                </ThemeProvider>
            </Provider>
        </StrictMode>
    )
}

main()

import LinkedinBoxFillIcon from 'remixicon-react/LinkedinBoxFillIcon'
import GithubFillIcon from 'remixicon-react/GithubFillIcon'

import { component } from '../../../utilities/component'
import { Text } from '../../common/text/Text'
import { Tilt } from '../../common/tilt/Tilt'

import {
    CompanyPageContainer,
    CompanyPageGrid,
    CompanyPageSummary,
    SectionSeparator,
    OwnerLetterSection,
    OwnerPictureContainer,
    OwnerPicture,
    OwnerLinks,
    OwnerLink
} from './CompanyPage.styled'

export const CompanyPage = component(() => {
    return (
        <CompanyPageContainer>
            <CompanyPageGrid>
                <CompanyPageSummary>
                    <Text font='heading' color='foreground' weight={700} size={40} transform='uppercase'>
                        Our Company
                    </Text>
                    <Text color='foreground' size={20} lineHeight={1.5}>
                        Fyrware was founded by a group of veteran technologists and media experts.
                        We specialize in custom software development and marketing strategies. Our leadership team has
                        a combined experience of over thirty years working in the technology industry; let us
                        partner with you to craft unique, interactive, and engaging experiences for your customers.
                        <p/>
                        We also have a long and successful track record of meaningful research and development projects.
                        Whether you're looking for engaging experiences for your customers, or custom technology solutions
                        to solve your unique business or engineering problems, our team is eager to work with you!
                    </Text>
                </CompanyPageSummary>
                <SectionSeparator/>
                <OwnerLetterSection>
                    <Text font='heading' color='foreground' weight={700} size={40} transform='uppercase'>
                        Letter from the CEO
                    </Text>
                    <Text color='foreground' size={20} lineHeight={1.5}>
                        I first co-founded Fyrware back in 2011 with my business partner at the time,
                        to whom I will be forever grateful. We completed several client projects before dissolving
                        the LLC and going our separate ways. Fast forward ten years later, having been heavily immersed
                        in the technology industry, I have a brand new team that's just as excited as I was
                        when I started over a decade ago.
                        <p/>
                        We are a team of solution-oriented "get-it-done-ers". There are challenges in every project,
                        but our secret to success is to just keep moving forward. Accountability, but not blame. We love
                        to experiment along the way to find the best solution for your project, but are also not afraid
                        to admit when we got it wrong in order to reorient the ship.
                        <p/>
                        With over thirty years of experience in our leadership team, I know we can do
                        anything that we put our minds to; from engineering custom software solutions for your business, to
                        crafting interactive digital experiences for your customers, we find passion in every project
                        that we take on. It's a massive responsibility and great joy to work hard for our clients.
                        <p/>
                        I look forward to being an advocate for you and your projects.
                        <p/>
                        <strong>Nick Dugger</strong><br/>
                        Founder and CEO
                    </Text>
                </OwnerLetterSection>
                <OwnerPictureContainer>
                    <Tilt full-page-listening>
                        <OwnerPicture src='/images/ceo.png' draggable={false}/>
                    </Tilt>
                    <OwnerLinks>
                        <OwnerLink href='https://github.com/ndugger' target='_blank'>
                            <GithubFillIcon size={32} color='currentcolor'/>
                        </OwnerLink>
                        <OwnerLink href='https://www.linkedin.com/in/nick-dugger/' target='_blank'>
                            <LinkedinBoxFillIcon size={32} color='currentcolor'/>
                        </OwnerLink>
                    </OwnerLinks>
                </OwnerPictureContainer>
            </CompanyPageGrid>
        </CompanyPageContainer>
    )
})
import styled from 'styled-components'
import { colorWithOpacity } from '../../../utilities/color'

export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`

export const InputLabel = styled.label`
    padding-left: 24px;
    opacity: 0.66;
    
    & strong {
        color: ${props => props.theme.color.primary};
    }
`

export const InputField = styled.input`
    height: 48px;
    border: 2px solid ${props => colorWithOpacity(props.theme.color.foreground, 0.25)};
    border-radius: 100px;
    background: transparent;
    padding: 0 24px;
    font-family: ${props => props.theme.font.content};
    font-size: 16px;
    color: ${props => props.theme.color.foreground};
    
    &:hover {
        border: 2px solid ${props => colorWithOpacity(props.theme.color.foreground, 0.5)};
    }
    
    &:focus {
        border: 2px solid ${props => colorWithOpacity(props.theme.color.foreground, 0.88)};
        outline: none;
    }
    
    &[data-error=true] {
        border: 2px solid ${props => props.theme.color.primary} !important;
    }
    
    &::placeholder {
        color: rgb(0 0 0 / 33%)
    }
`

export const InputTextarea = styled.textarea`
    height: 248px;
    border: 2px solid ${props => colorWithOpacity(props.theme.color.foreground, 0.25)};
    border-radius: 25px;
    background: transparent;
    padding: 16px 24px;
    font-family: ${props => props.theme.font.content};
    font-size: 16px;
    color: ${props => props.theme.color.foreground};
    
    &:hover {
        border: 2px solid ${props => colorWithOpacity(props.theme.color.foreground, 0.5)};
    }
    
    &:focus {
        border: 2px solid ${props => colorWithOpacity(props.theme.color.foreground, 1)};
        outline: none;
    }
    
    &[data-error=true] {
        border: 2px solid ${props => props.theme.color.primary} !important;
    }
    
    &::placeholder {
        color: rgb(0 0 0 / 33%);
    }
`

export const InputErrorHint = styled.div`
    padding-left: 24px;
`

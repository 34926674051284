import styled, { keyframes } from 'styled-components'

const fadeIn = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`

export const CompanyPageContainer = styled.main`
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding-top: 64px;
    animation: ${fadeIn} 200ms linear;
    
    @media (max-width: ${props => props.theme.breakpoint.laptop}px) {
        padding: 64px 56px 0;
    }
    
    @media (max-width: ${props => props.theme.breakpoint.tablet}px) {
        padding: 48px 16px 0;
    }
`

export const CompanyPageGrid = styled.section`
    display: grid;
    row-gap: 32px;
    column-gap: 32px;
    grid-template-columns: 280px repeat(2, 1fr);
    
    @media (max-width: ${props => props.theme.breakpoint.tablet}px) {
        row-gap: 24px;
        column-gap: 24px;
        grid-template-columns: 120px repeat(2, 1fr);
    }
    
    @media (max-width: 675px) {
        grid-template-columns: 1fr;
    }
    
    @media (max-width: ${props => props.theme.breakpoint.mobile}px) {
        row-gap: 24px;
        column-gap: 24px;
        grid-template-columns: 1fr;
    }
`

export const CompanyPageSummary = styled.article`
    grid-column-start: 2;
    grid-column-end: span 2;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 0 24px 16px;
    text-align: justify;
    justify-content: flex-end;
    
    @media (max-width: 675px) {
        grid-column-start: 1;
        grid-column-end: 1;
    }
    
    @media (max-width: ${props => props.theme.breakpoint.mobile}px) {
        text-align: left;
        grid-column-start: 1;
        grid-column-end: 1;
    }
    
    @media (max-width: 360px) {
        padding: 0 8px 8px;
    }
`

export const SectionSeparator = styled.article`
    grid-column-start: 1;
    grid-column-end: span 3;
    height: 160px;
    margin: 0 0 24px;
    background-image: url(/images/cube_panel.png);
    background-size: cover;
    background-position: center 400px;
    position: relative;
    clip-path: polygon(
        80px 0,
        100% 0,
        100% calc(100% - 80px),
        calc(100% - 80px) 100%,
        0 100%,
        0 80px
    );
    
    @media (max-width: ${props => props.theme.breakpoint.tablet}px) {
        height: 100px;
        clip-path: polygon(
            50px 0,
            100% 0,
            100% calc(100% - 50px),
            calc(100% - 50px) 100%,
            0 100%,
            0 50px
        );
    }
    
    @media (max-width: 675px) {
        grid-column-end: 1;
    }
`

export const OwnerLetterSection = styled.article`
    grid-column-start: 1;
    grid-column-end: span 2;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 0 24px 24px;
    text-align: justify;
    
    @media (max-width: 675px) {
        grid-column-start: 1;
        grid-column-end: 1;
    }
`

export const OwnerPictureContainer = styled.div`
    grid-column-start: 3;
    display: flex;
    max-width: 256px;
    flex-direction: column;
    align-items: center;
    padding-top: 80px;
    gap: 16px;
    
    @media (max-width: 675px) {
        grid-column-start: 1;
        grid-column-end: 1;
        padding-top: 0;
        max-width: 220px;
    }
`

export const OwnerPicture = styled.img`
    border-radius: 100%;
    width: 180px;
    height: 180px;
    user-select: none;
`

export const OwnerLinks = styled.div`
    display: flex;
    gap: 16px;
`

export const OwnerLink = styled.a`
    color: ${props => props.theme.color.foreground};
    opacity: 0.85;
    
    &:hover {
        color: ${props => props.theme.color.primary};
        opacity: 1;
    }
`
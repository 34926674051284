import styled from 'styled-components'
import { colorWithOpacity } from '../../../utilities/color'

export const PrimaryButton = styled.button`
    background-color: ${props => props.theme.color.primary};
    border: 2px solid ${props => props.theme.color.primary};
    border-radius: 100px;
    color: ${props => props.theme.color.white};
    padding: 16px 30px 13px;
    
    &:hover {
        cursor: pointer;
        background-color: ${props => colorWithOpacity(props.theme.color.primary, 0.5)};
    }
    
    &:active {
        opacity: 0.66;
    }
    
    @media (max-width: 360px) {
        padding: 13px 24px 10px;
    }
    
    @media (max-width: 300px) {
        padding: 10px 16px 7px;
    }
`

export const SecondaryButton = styled.button`
    background-color: transparent;
    border: 2px solid ${props => props.theme.color.white};
    border-radius: 100px;
    color: ${props => props.theme.color.white};
    padding: 16px 30px 13px;
    
    &:hover {
        cursor: pointer;
        background-color: ${props => colorWithOpacity(props.theme.color.white, 0.33)};
    }
    
    &:active {
        opacity: 0.66;
    }
    
    @media (max-width: 360px) {
        padding: 13px 24px 10px;
    }
    
    @media (max-width: 300px) {
        padding: 10px 16px 7px;
    }
`
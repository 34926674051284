import styled, { keyframes } from 'styled-components'

const decorationCutSize = 120

const fadeIn = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`

export const HomePageContainer = styled.main`
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding-top: 64px;
    animation: ${fadeIn} 200ms linear;
    
    @media (max-width: ${props => props.theme.breakpoint.laptop}px) {
        padding: 64px 80px 0 0;
    }
    
    @media (max-width: ${props => props.theme.breakpoint.tablet}px) {
        padding: 40px 40px 0 0;
    }
    
    @media (max-width: 700px) {
        padding: 40px 24px 0;
    }
`

export const HomePageGrid = styled.section`
    display: grid;
    row-gap: 32px;
    column-gap: 32px;
    grid-template-columns ${decorationCutSize * 2}px repeat(2, 1fr);
    
    @media (max-width: ${props => props.theme.breakpoint.tablet}px) {
        row-gap: 24px;
        column-gap: 24px;
        grid-template-columns: ${decorationCutSize * 1.5}px repeat(2, 1fr);
    }
    
    @media (max-width: 800px) {
        grid-template-columns: ${decorationCutSize / 1.5}px repeat(2, 1fr);
    }
    
    @media (max-width: 700px) {
        grid-template-columns: repeat(2, 1fr);
    }
    
    @media (max-width: ${props => props.theme.breakpoint.mobile}px) {
        row-gap: 24px;
        column-gap: 24px;
        grid-template-columns: 1fr;
    }
`

export const HomePageSummary = styled.article`
    grid-column-start: 2;
    grid-column-end: span 2;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 0 24px 16px;
    text-align: justify;
    justify-content: flex-end;
    
    @media (max-width: 700px) {
        text-align: left;
        grid-column-start: 1;
        grid-column-end: span 2;
    }
    
    @media (max-width: ${props => props.theme.breakpoint.mobile}px) {
        text-align: left;
        grid-column-start: 1;
        grid-column-end: 1;
    }
    
    @media (max-width: 360px) {
        padding: 0 8px 8px;
    }
`

export const HomePageDecoration = styled.div`
    grid-row-start: 1;
    grid-row-end: span 3;
    background-image: url('/images/cube_panel.png');
    background-size: cover;
    background-position: bottom;
    position: relative;
    clip-path: polygon(
        0 0,
        calc(100% - ${decorationCutSize}px) 0,
        100% ${decorationCutSize}px,
        100% 100%,
        ${decorationCutSize}px 100%,
        0 calc(100% - ${decorationCutSize}px)
    );
    
    @media (max-width: ${props => props.theme.breakpoint.tablet}px) {
        clip-path: polygon(
            0 0,
            calc(100% - ${decorationCutSize / 2}px) 0,
            100% ${decorationCutSize / 2}px,
            100% 100%,
            ${decorationCutSize / 2}px 100%,
            0 calc(100% - ${decorationCutSize / 2}px)
        );
    }
    
    @media (max-width: 700px) {
        display: none;
    }
`

export const HomePageLogoSection = styled.section`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0 48px;
    
    @media (max-width: ${props => props.theme.breakpoint.laptop}px) {
        padding: 16px 0 48px 80px;
    }
    
    @media (max-width: ${props => props.theme.breakpoint.tablet}px) {
        padding: 16px 0 48px 40px;
    }
    
    @media (max-width: ${props => props.theme.breakpoint.mobile}px) {
        padding: 0 0 32px 0;
    }
`

export const HomePageLogo = styled.img`
    max-width: calc(100% / 7 - 32px);
    max-height: 64px;
    display: block;
    margin: 16px 32px;
    opacity: 0.66;
    user-select: none;
    
    @media (max-width: ${props => props.theme.breakpoint.laptop}px) {
        &:nth-last-of-type(1),
        &:nth-last-of-type(2),
        &:nth-last-of-type(3) {
            display: none;
        }
    }
    
    // @media (max-width: ${props => props.theme.breakpoint.tablet}px) {
    //     &:last-of-type(1) {
    //         display: none;
    //     }
    //     &:nth-last-of-type(2),
    //     &:nth-last-of-type(3) {
    //         display: unset;
    //     }
    // }
    
    //@media (max-width: 1024px) {
    //    &:nth-last-of-type(1),
    //    &:nth-last-of-type(2),
    //    &:nth-last-of-type(3),
    //    &:nth-last-of-type(4),
    //    &:nth-last-of-type(5),
    //    &:nth-last-of-type(6) {
    //        display: none;
    //    }
    //}
    
    @media (max-width: ${props => props.theme.breakpoint.mobile}px) {
        max-width: calc(100% / 5);
        margin: 16px;
        
        &:nth-last-of-type(1),
        &:nth-last-of-type(2),
        &:nth-last-of-type(3),
        &:nth-last-of-type(4),
        &:nth-last-of-type(5),
        &:nth-last-of-type(6),
        &:nth-last-of-type(7),
        &:nth-last-of-type(8),
        &:nth-last-of-type(9) {
             display: none;
        }
    }
`

import { component } from '../../../utilities/component'
import { Content } from '../content/Content'
import { Logo } from '../logo/Logo'
import { Text } from '../text/Text'

import {
    FooterContainer,
    FooterEdge,
    FooterContent,
    FooterSeparator,
    FooterMeta,
    FooterMetaRow,
    FooterMetaLink
} from './Footer.styled'

export const Footer = component(() => {
    return (
        <FooterContainer>
            <FooterEdge/>
            <Content>
                <FooterContent>
                    <FooterMeta>
                        <FooterMetaRow>
                            <Text size={13} color='white'>
                                <FooterMetaLink to='/legal'>
                                    Legal
                                </FooterMetaLink>
                            </Text>
                            <FooterSeparator/>
                            <Text size={13} color='white'>
                                <FooterMetaLink to='/privacy'>
                                    Privacy
                                </FooterMetaLink>
                            </Text>
                            <FooterSeparator/>
                            <Text size={13} color='white'>
                                <FooterMetaLink to='/company'>
                                    Company
                                </FooterMetaLink>
                            </Text>
                        </FooterMetaRow>
                        <FooterMetaRow style={{ opacity: 0.5 }}>
                            <Text size={12} color='background'>
                                &copy; Fyrware LLC {new Date().getFullYear()}. All Rights Reserved.
                            </Text>
                        </FooterMetaRow>
                    </FooterMeta>
                    <FooterMetaLink to='/'>
                        <Logo height={40} color='primary'/>
                    </FooterMetaLink>
                </FooterContent>
            </Content>
        </FooterContainer>
    )
})

import { PropsWithChildren } from 'react'

import { component } from '../../../utilities/component'
import { Text } from '../text/Text'

import { PrimaryButton, SecondaryButton } from './Button.styled'

interface ButtonProperties extends PropsWithChildren {
    variant: 'primary' | 'secondary'
    submit?: boolean
    onClick?: () => void
}

export const Button = component<ButtonProperties>((properties) => {

    if (properties.variant === 'primary') {
        return (
            <PrimaryButton type={properties.submit ? 'submit' : 'button'} onClick={properties.onClick}>
                <Text font='heading' color='current' weight={700} size={14} transform='uppercase'>
                    {properties.children}
                </Text>
            </PrimaryButton>
        )
    } else if (properties.variant === 'secondary') {
        return (
            <SecondaryButton type={properties.submit ? 'submit' : 'button'} onClick={properties.onClick}>
                <Text font='heading' color='current' weight={700} size={14} transform='uppercase'>
                    {properties.children}
                </Text>
            </SecondaryButton>
        )
    }

    return null
})
import { PropsWithChildren } from 'react'

import { component } from '../../../utilities/component'

import {
    CardContainer
} from './Card.styled'

export const Card = component<PropsWithChildren>((properties) => {
    return (
        <CardContainer>
            {properties.children}
        </CardContainer>
    )
})

import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { colorWithOpacity } from '../../../utilities/color'

const mobileSize = 675

export const NavigationUnderside = styled.div`
    width: 100%;
    height: 80px;
    margin-bottom: -80px;
    position: sticky;
    top: 0;
    flex-shrink: 0;
    background-image: url("/images/cube_panel.png");
    background-size: cover;
    background-position: center;
    opacity: 0.66;
    z-index: 0;
`

export const NavigationContainer = styled.header`
    width: 100%;
    height: 80px;
    margin-bottom: -80px;
    position: sticky;
    top: 0;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    background-color: ${props => colorWithOpacity(props.theme.color.external, 0.6)};
    backdrop-filter: blur(20px);
    z-index: 100;
`

export const NavigationContent = styled.nav`
    max-width: ${props => props.theme.container.inner}px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    
    @media (max-width: ${props => props.theme.breakpoint.laptop}px) {
        padding: 0 80px;
    }
    
    @media (max-width: ${props => props.theme.breakpoint.tablet}px) {
        padding: 0 40px;
    }
`

export const NavigationLogoText = styled.span`
    margin-top: 4px;
    margin-left: 12px;
`

export const NavigationLogoLink = styled(Link)`
    color: white;
    text-decoration: none;
    display: flex;
    align-items: center;
`

export const NavigationMobileMenu = styled.div`
    display: none;
    flex-grow: 1;
    align-items: center;
    justify-content: flex-end;
    color: ${props => props.theme.color.white};
    
    @media (max-width: ${mobileSize}px) {
        display: flex;
    }
`

export const NavigationMobileMenuList = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 24px;
    position: fixed;
    left: 0;
    right: 0;
    top: 80px;
    bottom: 0;
    background-color: ${props => colorWithOpacity(props.theme.color.external, 0.85)};
    backdrop-filter: blur(20px);
    margin: 0;
    padding: 40px;
    list-style: none;
    z-index: 1;
`

export const NavigationList = styled.ul`
    display: flex;
    gap: 40px;
    flex-grow: 1;
    align-items: center;
    justify-content: flex-end;
    list-style: none;
    margin: 0;
    padding: 0;
    
    @media (max-width: ${mobileSize}px) {
        display: none;
    }
`

interface NavigationListItemLinkProperties {
    active?: boolean
}

export const NavigationListItemLink = styled(Link)<NavigationListItemLinkProperties>`
    color: ${props => props.active ? props.theme.color.primary : props.theme.color.white};
    text-decoration: none;
    margin-top: 2px;
    display: inline-block;
    position: relative;
    
    &::after {
        content: '';
        width: 2px;
        height: 2px;
        background-color: ${props => props.active ? props.theme.color.white : 'transparent'};
        box-shadow: 
            -8px 0 0 ${props => props.active ? props.theme.color.white : 'transparent'}, 
            8px 0 0 ${props => props.active ? props.theme.color.white : 'transparent'};
        position: absolute;
        left: calc(50% - 1px);
        bottom: -5px;
    }
    
    &:hover {
        color: ${props => props.theme.color.primary};
    }
`

export const NavigationExternalLink = styled.a`
    color: ${props => props.theme.color.white};
    text-decoration: none;
    margin-top: 2px;
    display: inline-block;
    
    &:hover {
        color: ${props => props.theme.color.primary};
    }
`
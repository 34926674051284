import { component } from '../../../utilities/component'
import { Theme, theme } from '../../../utilities/theme'

interface LogoProperties {
    color?: keyof Theme['color']
    width?: number
    height?: number
}

export const Logo = component<LogoProperties>((properties) => {
    return (
        <svg width={properties.width} height={properties.height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200.89 286.65">
            <g>
                <path fill={theme.color[properties.color ?? 'primary']} d="M132.74 92.1c8.25-8.96 8.23-19.5 11.03-30.19 4.22 4.49 7.83 8.05 11.13 11.87 14.71 17.08 28.27 35.11 37.04 55.95 4.31 10.24 6.64 21.58 8.2 32.65 2.23 15.88-.6 31.53-6.71 46.32-7.67 18.57-19.68 33.87-36.56 45.03-5.43 3.59-10.14 3.11-13.47-.73-3.13-3.62-2.8-9.9 1.11-14.1 13.01-13.97 18.91-30.55 16.89-49.4-2.03-18.84-11.22-33.98-27.04-44.59-18.23-12.23-37.86-13.39-58.13-5.92-21.23 7.82-37.5 31.96-38.02 54.68-.4 17.44 5.34 32.22 16.94 44.99 4.09 4.5 4.55 10.82 1.21 14.47-3.57 3.91-8.32 4.03-13.9.17-19.39-13.43-33.91-30.65-39.18-54.06-6.62-29.43-3.65-57.35 12.86-83.77 12.74-20.38 18.56-24.76 35.6-41.54 8.76-8.62 26.48-31.57 30.79-43.39 2.75-7.55 4.1-15.63 5.93-23.5.44-1.88.31-3.88.53-7.04 3.09 1.47 5.6 2.21 7.58 3.67 16.28 11.98 25.37 28.13 27.84 48.18 1.34 10.86 3.03 21.68 4.83 32.47.39 2.31 1.94 4.42 3.48 7.77Z"/>
                <path fill={theme.color[properties.color ?? 'primary']} d="M89.98 249.08c0-7.98-.05-20.04 0-28.02.04-6.09 4.24-10.36 10.09-10.44 5.7-.07 10.45 4.18 10.66 10.19.27 7.63.1 19.36.1 27 0 8.15.15 20.39-.07 28.53-.2 7.45-3.54 10.41-10.96 10.31-6.22-.09-9.68-2.95-9.78-8.52-.15-8.32-.04-20.72-.05-29.04Z"/>
            </g>
        </svg>
    )
})
import styled from 'styled-components'
import { Theme } from '../../../utilities/theme'

interface TextContainerProperties {
    font: keyof Theme['font']
    color: keyof Theme['color']
    weight: 200 | 400 | 700
    size: number
    transform: 'uppercase' | 'lowercase' | 'capitalize' | 'none'
    lineHeight: number | 'auto'
}

export const TextContainer = styled.span<TextContainerProperties>`
    color: ${props => props.theme.color[props.color]};
    font-family: ${props => props.theme.font[props.font]};
    font-weight: ${props => props.weight};
    font-size: ${props => props.size}px;
    text-transform: ${props => props.transform};
    line-height: ${props => props.lineHeight};
    text-align: start;
    word-spacing: 0.125em;
`
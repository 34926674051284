export interface Theme {
    color: {
        current: string
        black: string
        white: string
        external: string
        background: string
        foreground: string
        primary: string
    }
    font: {
        heading: string
        content: string
    }
    container: {
        outer: number
        inner: number
    }
    breakpoint: {
        mobile: number
        tablet: number
        laptop: number
        desktop: number
        tv: number
    }
}

export const theme: Theme = {
    color: {
        current: 'currentcolor',
        white: '#ffffff',
        black: '#000000',
        external: '#12141b',
        background: '#e8ebf5',
        foreground: '#3b3f50',
        primary: '#f53f08',
    },
    font: {
        heading: '"Bahnschrift", "Roboto Condensed", "Impact", sans-serif',
        content: '"Manrope", sans-serif'
    },
    container: {
        outer: 1440,
        inner: 1080
    },
    breakpoint: {
        mobile: 540,
        tablet: 960,
        laptop: 1280,
        desktop: 2560,
        tv: 3840
    }
}

import styled, { keyframes } from 'styled-components'

const fadeIn = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`

export const LegalTermsPageContainer = styled.main`
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding-top: 64px;
    animation: ${fadeIn} 200ms linear;
    
    & h2, & h3, & h4, & h5 {
        font-family: ${props => props.theme.font.heading};
        text-transform: uppercase;
        margin-bottom: 0;
    }
    
    & h2 {
        font-size: 2.5em;
        margin-top: 16px;
    }
    
    & h3 {
        font-size: 1.75em;
    }
    
    & h4 {
        font-size: 1.25em;
    }
    
    & h5 {
        font-size: 1em;
    }
    
    & a {
        color: ${props => props.theme.color.primary};
        font-weight: 700;
    }
    
    @media (max-width: ${props => props.theme.breakpoint.laptop}px) {
        padding: 64px 80px;
    }
    
    @media (max-width: ${props => props.theme.breakpoint.tablet}px) {
        padding: 24px 40px;
    }
`
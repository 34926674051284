import { ReactEventHandler, useCallback, useState } from 'react'
import PhoneLineIcon from 'remixicon-react/PhoneLineIcon'
import MailSendLineIcon from 'remixicon-react/MailSendLineIcon'

import { component } from '../../../utilities/component'
import { Input } from '../../common/input/Input'
import { Text } from '../../common/text/Text'

import {
    ContactPageContainer,
    ContactPageGrid,
    ContactPageHeading,
    ContactDetailsSection,
    ContactDetailsLineItem,
    ContactPageSubmissionMessage,
    ContactPageForm,
    ContactPageButtons,
    ContactPageSpinnerContainer,
    ContactPageSpinner
} from './ContactPage.styled'
import { Button } from '../../common/button/Button'

interface ContactFormData {
    'form-name': string
    'contact-name': string
    'contact-email': string
    'contact-phone': string
    'contact-message': string
}

export const ContactPage = component(() => {
    const [missingRequiredFields, setMissingRequiredFields] = useState([] as string[])
    const [submissionSuccess, setSubmissionSuccess] = useState(false)
    const [submissionPending, setSubmissionPending] = useState(false)
    const [submissionError, setSubmissionError] = useState('')

    const handleFormSubmit = useCallback<ReactEventHandler<HTMLFormElement>>((event) => {
        event.preventDefault()

        const form = event.target as HTMLFormElement
        const fields = [
            form.elements.namedItem('contact-name'),
            form.elements.namedItem('contact-email'),
            form.elements.namedItem('contact-phone'),
            form.elements.namedItem('contact-message')
        ]

        const formData = fields.reduce((data, element) => {
            const input = element as HTMLInputElement | HTMLTextAreaElement

            return Object.assign(data, {
                [input.name]: input.value
            })
        }, {
            'form-name': 'First Contact'
        } as ContactFormData)

        const missingFields = [] as string[]

        if (!formData['contact-name']) {
            missingFields.push('contact-name')
        }

        if (!formData['contact-email'] || !formData['contact-email'].includes('@')) {
            missingFields.push('contact-email')
        }

        if (missingFields.length > 0) {
            return setMissingRequiredFields(missingFields)
        } else {
            setMissingRequiredFields([])
        }

        const encodedFormData = Object.keys(formData).map(key => {
            return `${encodeURIComponent(key)}=${encodeURIComponent(formData[key as keyof ContactFormData])}`
        }).join('&')

        setSubmissionPending(true)

        fetch('/', {
            method: 'POST',
            headers: {
                'content-type': 'application/x-www-form-urlencoded'
            },
            body: encodedFormData
        }).then(response => {
            if (response.ok) {
                setSubmissionSuccess(true)
            } else {
                response.text().then(text => setSubmissionError(text || 'Server Error'))
            }
        }).catch(error => {
            if (error instanceof Error) {
                setSubmissionError(error.message)
            } else {
                setSubmissionError(error)
            }
        }).finally(() => {
            setSubmissionPending(false)
        })
    }, [])

    return (
        <ContactPageContainer>
            <ContactPageGrid>
                <ContactPageHeading>
                    <Text font='heading' color='foreground' weight={700} size={40} transform='uppercase'>
                        Make First Contact
                    </Text>
                    <Text color='foreground' size={20} lineHeight={1.5}>
                        Greetings, Earthling! We can't wait to hear from you. Whether you'd like to work with us on your
                        next project, or if you simply want to say hello, we're all eyes, ears, and antennas! let us know what's on
                        your mind, we'll get back to you as soon as possible.
                    </Text>
                </ContactPageHeading>
                <ContactDetailsSection>
                    <ContactDetailsLineItem>
                        <PhoneLineIcon color='currentcolor' size={30}/>
                        <Text font='heading' size={18} weight={700}>
                            1 (888) 694-2007
                        </Text>
                    </ContactDetailsLineItem>
                    <ContactDetailsLineItem>
                        <MailSendLineIcon color='currentcolor' size={30}/>
                        <Text font='heading' size={18} weight={700}>
                            greetings@fyrware.com
                        </Text>
                    </ContactDetailsLineItem>
                </ContactDetailsSection>
                {submissionPending && (
                    <ContactPageSpinnerContainer>
                        <ContactPageSpinner/>
                    </ContactPageSpinnerContainer>
                )}
                {(submissionError || submissionSuccess) && (
                    <ContactPageSubmissionMessage>
                        {submissionError && (
                            <Text color='primary' size={18} weight={700}>
                                Uh oh! Something went wrong: {submissionError || 'Server Error'}
                            </Text>
                        )}
                        {submissionSuccess && (
                            <Text color='foreground' size={18} weight={700}>
                                Success! We'll get back to you as soon as possible.
                            </Text>
                        )}
                    </ContactPageSubmissionMessage>
                )}
                <ContactPageForm hidden={submissionPending || submissionSuccess} onSubmit={handleFormSubmit}>
                    <Input name='contact-name' label='Name' placeholder='Human Person' required error={missingRequiredFields.includes('contact-name') ? 'Name is required' : undefined}/>
                    <Input name='contact-email' label='Email' placeholder='human.person@earth.com' required error={missingRequiredFields.includes('contact-email') ? 'Email is required & must be valid' : undefined}/>
                    <Input name='contact-phone' label='Phone' placeholder='XXXXXXXXXX'/>
                    <Input name='contact-message' label='Message' textarea/>
                    <ContactPageButtons>
                        <Button submit variant='primary'>
                            Send Message
                        </Button>
                    </ContactPageButtons>
                </ContactPageForm>
            </ContactPageGrid>
        </ContactPageContainer>
    )
})

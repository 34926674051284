import styled from 'styled-components'

export const RootContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    // overflow: auto;
    background-color: ${props => props.theme.color.external};
`

export const RootContent = styled.div`
    max-width: ${props => props.theme.container.outer}px;
    width: 100%;
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    align-items: center;
    flex-direction: column;
    background-color: ${props => props.theme.color.background};
    position: relative;
`
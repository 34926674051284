import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom'

import { component } from '../utilities/component'

import { RootContainer, RootContent } from './Root.styled'
import { Header } from './common/header/Header'
import { Navigation, NavigationUnderside } from './common/navigation/Navigation'
import { Content } from './common/content/Content'
import { HomePage } from './pages/home/HomePage'
import { Footer } from './common/footer/Footer'
import { CompanyPage } from './pages/company/CompanyPage'
import { useEffect } from 'react'
import { ContactPage } from './pages/contact/ContactPage'
import { PrivacyPolicyPage } from './pages/privacy/PrivacyPolicyPage'
import { LegalTermsPage } from './pages/legal/LegalTermsPage'

export const Root = component(() => {
    const location = useLocation()

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }, [location])

    return (
        <RootContainer>
            <RootContent>
                <NavigationUnderside/>
                <Navigation/>
                <Header/>
                <Content>
                    <Routes>
                        <Route path='/' element={<HomePage/>}/>
                        <Route path='/company' element={<CompanyPage/>}/>
                        <Route path='/contact' element={<ContactPage/>}/>
                        <Route path='/privacy' element={<PrivacyPolicyPage/>}/>
                        <Route path='/legal' element={<LegalTermsPage/>}/>
                    </Routes>
                </Content>
                <Footer/>
            </RootContent>
        </RootContainer>
    )
})
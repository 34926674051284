import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const FooterContainer = styled.footer`
    width: 100%;
    background-color: ${props => props.theme.color.external};
    background-image: url("/images/tech_tunnel.png");
    background-size: ${props => props.theme.container.outer}px;
    background-attachment: fixed;
    background-position: center bottom;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    @media (max-width: ${props => props.theme.container.outer}px) {
        background-size: cover;
    }
    
    @media (max-width: ${props => props.theme.breakpoint.tablet}px) {
        background-size: 150%;
    }
    
    @media (max-width: ${props => props.theme.breakpoint.mobile}px) {
        background-size: 300%;
    }
`

export const FooterEdge = styled.div`
    width: 100%;
    height: 80px;
    background-color: ${props => props.theme.color.background};
    margin-top: -1px;
    position: relative;
    clip-path: polygon(
        0 0,
        100% 0,
        0 100%
    );
`

export const FooterContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 24px;
    margin: 24px 0 64px;
    
    @media (max-width: ${props => props.theme.breakpoint.laptop}px) {
        margin: 24px 80px 64px;
    }
    
    @media (max-width: ${props => props.theme.breakpoint.tablet}px) {
        margin: 12px 40px 48px;
    }
`

export const FooterSeparator = styled.div`
    height: 100%;
    min-height: 18px;
    border-left: 1px solid rgb(255, 255, 255, 0.2);
`

export const FooterMeta = styled.div`
    display: flex;
    flex-direction: column;
    gap: 6px;
`

export const FooterMetaRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
`

export const FooterMetaLink = styled(Link)`
    color: ${props => props.theme.color.background};
    text-decoration: none;
    
    &:hover {
        color: ${props => props.theme.color.primary};
    }
`

import styled from 'styled-components'

export const HeaderContainer = styled.section`
    width: 100%;
    height: 640px;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    background-color: ${props => props.theme.color.foreground};
    background-image: url("/images/tech_tunnel.png");
    background-size: ${props => props.theme.container.outer}px;
    background-attachment: fixed;
    background-position: center top;
    border-right: 1px solid ${props => props.theme.color.external};
    clip-path: polygon(
        -1px -1px,
        calc(100% + 1px) -1px,
        calc(100% + 1px) calc(100% - 80px),
        -1px 100%
    );
    z-index: 0;
    transition: height 200ms ease-in-out;
    
    &.page {
        height: 333px !important;
    }
    
    @media (max-width: ${props => props.theme.container.outer}px) {
        background-size: 100%;
        border-right: none;
    }
    
    @media (max-width: ${props => props.theme.breakpoint.tablet}px) {
        height: 512px;
        clip-path: polygon(
            0 0,
            calc(100% + 1px) 0,
            calc(100% + 1px) calc(100% - 40px),
            0 100%
        );

        &.page {
          height: 200px !important;
        }
    }
    
    @media (max-width: 740px) {
        height: 420px;
    }
    
    @media (max-width: 675px) {
        background-size: 675px;
    }
    
    @media (max-width: ${props => props.theme.breakpoint.mobile}px) {
        height: 440px;
        clip-path: polygon(
            0 0,
            calc(100% + 1px) 0,
            calc(100% + 1px) calc(100% - 40px),
            0 100%
        );
    }
    
    @media (max-width: 400px) {
        height: 512px;
    }
    
    @media (max-width: 300px) {
        height: 540px;
    }
`

export const HeaderHeroDecoration = styled.div`
    max-width: ${props => props.theme.container.inner}px;
    width: 100%;
    height: 100%;
    padding-top: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    opacity: 1;
    transition: opacity 200ms ease-in-out;
    
    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-image: url("/images/tech_bust.png");
        background-repeat: no-repeat;
        background-position: right 120px;
        transition: top 200ms ease-in-out;
        pointer-events: none;
        z-index: 1;
    }
    
    &[hidden] {
        opacity: 0;
        
        &::before {
            top: 120px;
        }
    }
    
    & h1 {
        margin: 0;
    }
    
    @media (max-width: ${props => props.theme.breakpoint.laptop}px) {
        padding: 12px 80px 0;
        
        &::before {
            background-position: calc(100% - 80px) 120px;
        }
    }
    
    @media (max-width: ${props => props.theme.breakpoint.tablet}px) {
        padding: 12px 40px 0;
        
        &::before {
            background-position: calc(100% - 40px) 24px;
            background-size: 70%;
        }
    }
    
    @media (max-width: 740px) {
        & h1 {
            font-size: 36px;
        }
    }
    
    @media (max-width: 675px) {
        padding: 12px 32px 0;
        
        & h1 {
            font-size: 32px;
        }
        
        &::before {
            background-size: 75%;
        }
    }
    
    @media (max-width: ${props => props.theme.breakpoint.mobile}px) {
        &::before {
            display: none;
        }
    }
    
    @media (max-width: 300px) {
        & h1 {
            font-size: 30px;
        }
    }
`

export const HeaderHeroButtons = styled.div`
    margin-top: 32px;
    display: flex;
    gap: 24px;
    
    @media (max-width: 375px) {
        gap: 0;
        justify-content: space-between;
    }
`

export const HeaderPageDecoration = styled.div`
    max-width: ${props => props.theme.container.inner}px;
    width: 100%;
    height: 512px;
    background-image: url(/images/broken_bust.png);
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: 140px;
    opacity: 1;
    transition: all 200ms ease-in-out;
    pointer-events: none;
    
    &[hidden] {
        display: block;
        top: 0;
        opacity: 0;
    }
    
    @media (max-width: ${props => props.theme.breakpoint.laptop}px) {
        background-position: 80px top;
        height: 480px;
    }
    
    @media (max-width: ${props => props.theme.breakpoint.tablet}px) {
        background-position: 20px top;
        height: 320px;
        top: 140px;
    }
    
    @media (max-width: 675px) {
        display: none;
    }
`

export const HeaderContactDecoration = styled.div`
    max-width: ${props => props.theme.container.inner}px;
    width: 100%;
    height: 512px;
    background-image: url(/images/ufo.png);
    background-position: right top;
    background-repeat: no-repeat;
    background-size: 480px;
    position: absolute;
    top: 120px;
    opacity: 1;
    transition: all 200ms ease-in-out;
    pointer-events: none;
    
    &[hidden] {
        display: block;
        top: 0;
        opacity: 0;
    }
    
    @media (max-width: ${props => props.theme.breakpoint.tablet}px) {
        background-size: 380px;
        background-position: calc(100% - 40px) top;
    }
    
    @media (max-width: ${props => props.theme.breakpoint.mobile}px) {
        display: none;
    }
`
import { PropsWithChildren } from 'react'

import { component } from '../../../utilities/component'

import { ContentContainer } from './Content.styled'

export const Content = component<PropsWithChildren>((properties) => {
    return (
        <ContentContainer>
            {properties.children}
        </ContentContainer>
    )
})
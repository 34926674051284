import { PropsWithChildren, useEffect, useRef } from 'react'
import VanillaTilt, { TiltOptions } from 'vanilla-tilt'

import { component } from '../../../utilities/component'

export const Tilt = component<PropsWithChildren<TiltOptions & { stretch?: boolean }>>(({children, stretch, ...options}) => {
    const ref = useRef<HTMLDivElement>(null)

    useEffect(() => {
        VanillaTilt.init(ref.current!, {
            reverse: true,
            glare: true,
            'max-glare': 0.25,
            speed: 500,
            ...options
        })
    }, [options])

    return (
        <div ref={ref} style={stretch ? { display: 'flex', alignItems: 'stretch', justifyContent: 'stretch' } : {}}>
            {children}
        </div>
    )
})
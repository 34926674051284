import { useLocation } from 'react-router-dom'
import LinkedinBoxFillIcon from 'remixicon-react/LinkedinBoxFillIcon'
import GithubFillIcon from 'remixicon-react/GithubFillIcon'
import TwitterFillIcon from 'remixicon-react/TwitterFillIcon'

import { component } from '../../../utilities/component'

import { Logo } from '../logo/Logo'
import { Text } from '../text/Text'

import {
    NavigationUnderside,
    NavigationContainer,
    NavigationContent,
    NavigationLogoText,
    NavigationLogoLink,
    NavigationList,
    NavigationListItemLink,
    NavigationExternalLink,
    NavigationMobileMenu,
    NavigationMobileMenuList
} from './Navigation.styled'
import MenuFillIcon from 'remixicon-react/MenuFillIcon'
import { useCallback, useState } from 'react'

export {
    NavigationUnderside
}

export const Navigation = component(() => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

    const location = useLocation()

    const handleMobileMenuClick = useCallback(() => {
        setMobileMenuOpen(!mobileMenuOpen)
    }, [mobileMenuOpen])

    return (
        <>
            <NavigationContainer>
                <NavigationContent>
                    <NavigationLogoLink to='/'>
                        <Logo height={50}/>
                        <NavigationLogoText>
                            <Text font='heading' color='white' weight={700} size={24}>
                                Fyrware
                            </Text>
                        </NavigationLogoText>
                    </NavigationLogoLink>
                    <NavigationList>
                        <li>
                            <NavigationListItemLink active={location.pathname === '/'} to='/'>
                                <Text font='heading' color='current' weight={700} size={16} transform='uppercase'>
                                    Home
                                </Text>
                            </NavigationListItemLink>
                        </li>
                        <li>
                            <NavigationListItemLink active={location.pathname === '/company'} to='/company'>
                                <Text font='heading' color='current' weight={700} size={16} transform='uppercase'>
                                    Company
                                </Text>
                            </NavigationListItemLink>
                        </li>
                        <li>
                            <NavigationListItemLink active={location.pathname === '/contact'} to='/contact'>
                                <Text font='heading' color='current' weight={700} size={16} transform='uppercase'>
                                    Contact
                                </Text>
                            </NavigationListItemLink>
                        </li>
                        <li>
                            <NavigationExternalLink href='https://github.com/fyrware' target='_blank'>
                                <GithubFillIcon size={24} color='currentcolor'/>
                            </NavigationExternalLink>
                        </li>
                        <li>
                            <NavigationExternalLink href='https://twitter.com/fyrware_com' target='_blank'>
                                <TwitterFillIcon size={24} color='currentcolor'/>
                            </NavigationExternalLink>
                        </li>
                        <li>
                            <NavigationExternalLink href='https://www.linkedin.com/company/fyrware' target='_blank'>
                                <LinkedinBoxFillIcon size={24} color='currentcolor'/>
                            </NavigationExternalLink>
                        </li>
                    </NavigationList>
                    <NavigationMobileMenu>
                        <MenuFillIcon size={36} color='currentcolor' onClick={handleMobileMenuClick}/>
                    </NavigationMobileMenu>
                </NavigationContent>
            </NavigationContainer>
            {mobileMenuOpen && (
                <NavigationMobileMenuList onClick={handleMobileMenuClick}>
                    <li>
                        <NavigationListItemLink active={location.pathname === '/'} to='/'>
                            <Text font='heading' color='current' weight={700} size={20} transform='uppercase'>
                                Home
                            </Text>
                        </NavigationListItemLink>
                    </li>
                    <li>
                        <NavigationListItemLink active={location.pathname === '/company'} to='/company'>
                            <Text font='heading' color='current' weight={700} size={20} transform='uppercase'>
                                Company
                            </Text>
                        </NavigationListItemLink>
                    </li>
                    <li>
                        <NavigationListItemLink active={location.pathname === '/contact'} to='/contact'>
                            <Text font='heading' color='current' weight={700} size={20} transform='uppercase'>
                                Contact
                            </Text>
                        </NavigationListItemLink>
                    </li>
                    <li>
                        <NavigationExternalLink href='https://github.com/fyrware' target='_blank'>
                            <GithubFillIcon size={32} color='currentcolor'/>
                        </NavigationExternalLink>
                    </li>
                    {/*<li>*/}
                    {/*    <NavigationExternalLink href='https://twitter.com/fyrware_support' target='_blank'>*/}
                    {/*        <TwitterFillIcon size={32} color='currentcolor'/>*/}
                    {/*    </NavigationExternalLink>*/}
                    {/*</li>*/}
                    <li>
                        <NavigationExternalLink href='https://www.linkedin.com/company/fyrware' target='_blank'>
                            <LinkedinBoxFillIcon size={32} color='currentcolor'/>
                        </NavigationExternalLink>
                    </li>
                </NavigationMobileMenuList>
            )}
        </>
    )
})
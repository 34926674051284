import { useCallback } from 'react'

import { component } from '../../../utilities/component'

import {
    HeaderContainer,
    HeaderHeroDecoration,
    HeaderHeroButtons,
    HeaderPageDecoration,
    HeaderContactDecoration
} from './Header.styled'
import { Text } from '../text/Text'
import { Button } from '../button/Button'
import { useLocation, useNavigate } from 'react-router-dom'
import { Tilt } from '../tilt/Tilt'

export const Header = component(() => {
    const location = useLocation()
    const navigate = useNavigate()

    const handleLearnMoreClick = useCallback(() => {
        const learnMoreSection = document.getElementById('our_services')!
        const learnMorePosition = learnMoreSection.getBoundingClientRect().top
        const offsetPosition = learnMorePosition + window.scrollY - 114

        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
        })
    }, [])

    const handleContactUsClick = useCallback(() => {
        navigate('/contact')
    }, [])

    const isIndex = location.pathname === '/'
    const isCompany = location.pathname === '/company'
    const isContact = location.pathname === '/contact'

    return (
        <>
            <HeaderContainer className={isIndex ? 'index' : 'page'}>
                <HeaderHeroDecoration hidden={!isIndex}>
                    <Tilt max={7.25} glare={false} full-page-listening>
                        <Text as='h1' font='heading' color='white' weight={700} size={48} transform='uppercase'>
                            Technology &amp; Media<br/>
                            Consultants, Research<br/>
                            &amp; Development Experts
                        </Text>
                        <HeaderHeroButtons>
                            <Button variant='primary' onClick={handleLearnMoreClick}>
                                Learn More
                            </Button>
                            <Button variant='secondary' onClick={handleContactUsClick}>
                                Contact Us
                            </Button>
                        </HeaderHeroButtons>
                    </Tilt>
                </HeaderHeroDecoration>
            </HeaderContainer>
            <HeaderPageDecoration hidden={!isCompany}/>
            <HeaderContactDecoration hidden={!isContact}/>
        </>
    )
})

import { PropsWithChildren } from 'react'

import { component } from '../../../utilities/component'
import { Theme } from '../../../utilities/theme'

import { TextContainer } from './Text.styled'

interface TextProperties extends PropsWithChildren {
    as?: string
    font?: keyof Theme['font']
    color?: keyof Theme['color']
    weight?: 200 | 400 | 700
    size?: number
    transform?: 'uppercase' | 'lowercase' | 'capitalize'
    lineHeight?: number
}

export const Text = component<TextProperties>((properties) => {
    return (
        <TextContainer
            as={properties.as ?? 'span' as any}
            color={properties.color ?? 'current'}
            size={properties.size ?? 16}
            font={properties.font ?? 'content'}
            weight={properties.weight ?? 400}
            transform={properties.transform ?? 'none'}
            lineHeight={properties.lineHeight ?? 'normal'}>
            {properties.children}
        </TextContainer>
    )
})
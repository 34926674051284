import CodeSSlashFillIcon from 'remixicon-react/CodeSSlashFillIcon'
import CloudLineIcon from 'remixicon-react/CloudLineIcon'
import BubbleChartLineIcon from 'remixicon-react/BubbleChartLineIcon'
import CursorLineIcon from 'remixicon-react/CursorLineIcon'
import AccountPinCircleLineIcon from 'remixicon-react/AccountPinCircleLineIcon'
import LightbulbFlashLineIcon from 'remixicon-react/LightbulbFlashLineIcon'

import { component } from '../../../utilities/component'
import { Text } from '../../common/text/Text'
import { Tilt } from '../../common/tilt/Tilt'

import {
    HomePageContainer,
    HomePageGrid,
    HomePageSummary,
    HomePageDecoration,
    HomePageLogoSection,
    HomePageLogo
} from './HomePage.styled'
import { Card } from '../../common/card/Card'
import { colorWithOpacity } from '../../../utilities/color'
import { ReactNode } from 'react'
import { theme } from '../../../utilities/theme'

const services = [
    {
        name: 'Custom Software & DevOps Solutions',
        description: 'From web and native apps, to server and computer systems engineering, our team is well equipped to develop custom software to suit your business\' specific needs.',
        icon: <CodeSSlashFillIcon color='#fff' size={36}/>
    },
    {
        name: 'Cloud Architecture & Deployment Services',
        description: 'Whether you need your existing software transitioned into a cloud-ready state, or want us to deploy custom software to the cloud, we\'ve got you covered.',
        icon: <CloudLineIcon color='#fff' size={36}/>
    },
    // {
    //     name: 'Decentralized Apps & Strategies',
    //     description: 'We can help you architect and develop highly scalable, cryptographically stored, decentralized applications that make use of smart contracts.',
    //     icon: <BubbleChartLineIcon color='#fff' size={36}/>
    // },
    {
        name: 'Interactive & Online Digital Experiences',
        description: 'Want your website to feel like an interactive experience, or need a multiplayer system or application built? We can do that too.',
        icon: <CursorLineIcon color='#fff' size={36}/>
    },
    {
        name: 'Custom Community Development',
        description: 'Let us set up a custom membership experience for your customers, and deliver fun and unique perks to keep them engaged with your property.',
        icon: <AccountPinCircleLineIcon color='#fff' size={36}/>
    },
    // {
    //     name: 'Consumer Marketing, Branding, and Risk Analytics',
    //     description: 'If you need to refresh an existing brand or kickstart a new one, we can design, market, and analyze risks to your business to propel it forward.',
    //     icon: <LightbulbFlashLineIcon color='#fff' size={36}/>
    // },
]

export const logos = [
    {
        alt: 'Auth0',
        src: '/images/logos/auth0.png'
    },
    {
        alt: 'Amazon Web Services',
        src: '/images/logos/aws.png'
    },
    {
        alt: 'C++ Language',
        src: '/images/logos/cpp.png'
    },
    {
        alt: 'Discord',
        src: '/images/logos/discord.png'
    },
    {
        alt: 'Firebase',
        src: '/images/logos/firebase.png'
    },
    {
        alt: 'Flutter',
        src: '/images/logos/flutter.png',
    },
    {
        alt: 'Google Cloud Platform',
        src: '/images/logos/gcp.png'
    },
    {
        alt: 'GitHub',
        src: '/images/logos/github.png'
    },
    {
        alt: 'MySQL',
        src: '/images/logos/mysql.png'
    },
    {
        alt: 'Node.js',
        src: '/images/logos/nodejs.png'
    },
    {
        alt: 'OpenAPI Initiative',
        src: '/images/logos/openapi.png'
    },
    {
        alt: 'PostgreSQL',
        src: '/images/logos/postgres.png'
    },
    {
        alt: 'React',
        src: '/images/logos/react.png'
    },
    {
        alt: 'Socket.io',
        src: '/images/logos/socketio.png'
    },
    // {
    //     alt: 'Solidity',
    //     src: '/images/logos/solidity.png'
    // },
    {
        alt: 'Square Payments',
        src: '/images/logos/square.png'
    },
    {
        alt: 'Stripe Payments',
        src: '/images/logos/stripe.png'
    },
    {
        alt: 'TypeScript Language',
        src: '/images/logos/typescript.png'
    },
    // {
    //     alt: 'Unity Game Engine',
    //     src: '/images/logos/unity.png'
    // },
    // {
    //     alt: 'WordPress',
    //     src: '/images/logos/wordpress.png'
    // },
    {
        alt: 'YouTube',
        src: '/images/logos/youtube.png'
    }
]

function randomSort<Item>(array: Item[]): Item[] {
    return array.sort((a, b) => 0.5 - Math.random())
}

interface ServiceCardProperties {
    name: string
    description: string
    icon: ReactNode
}

const ServiceCard = component<ServiceCardProperties>((properties) => {
    return (
        <Tilt max={10} stretch>
            <Card>
                <div style={{
                    width: 36,
                    height: 36,
                    backgroundColor: colorWithOpacity(theme.color.primary, 0.33),
                    border: '2px solid ' + theme.color.primary,
                    borderRadius: '100%',
                    padding: 12,
                    marginBottom: 24
                }}>
                    {properties.icon}
                </div>
                <Text font='heading' color='white' weight={700} size={24} transform='uppercase'>
                    {properties.name}
                </Text>
                <div style={{
                    margin: '12px 0',
                    opacity: 0.66
                }}>
                    <Text font='content' color='white' lineHeight={1.6}>
                        {properties.description}
                    </Text>
                </div>
            </Card>
        </Tilt>
    )
})

export const HomePage = component(() => {
    return (
        <HomePageContainer>
            <HomePageGrid>
                <HomePageSummary id='our_services'>
                    <Text font='heading' color='foreground' weight={700} size={40} transform='uppercase'>
                        Our Services
                    </Text>
                    <Text color='foreground' size={20} lineHeight={1.5}>
                        Fyrware is a research and development company that focuses on modern technological advancements and digital media.
                        Our unique team provides consultancy services to our partners in the areas related to technology, marketing,
                        and community engagement. It is our mission to strive to provide the best experience possible to a wide variety of clients.
                    </Text>
                </HomePageSummary>
                <HomePageDecoration/>
                {services.map(service => (
                    <ServiceCard key={service.name} name={service.name} description={service.description} icon={service.icon}/>
                ))}
            </HomePageGrid>
            <HomePageLogoSection>
                {randomSort(logos).map(logo => (
                    <HomePageLogo key={logo.src} alt={logo.alt} src={logo.src} draggable={false}/>
                ))}
            </HomePageLogoSection>
        </HomePageContainer>
    )
})